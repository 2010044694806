import React, { useState, useContext } from 'react';
import {
  Box,
  Typography,
  Chip,
  Button,
  Container,
  Divider,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar
} from '@material-ui/core';
import { colors } from '@loggi/mar';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import HeaderWithCancel from '../../app/components/header-with-cancel';
import { BarcodeReader } from '../../app/components/barcode-readers';
import showSnackbar from '../../app/components/snackbar/snackbar-container';
import { ReactComponent as LocalShippingIcon } from '../../assets/images/local_shipping.svg';
import {
  playErrorBeep,
  playSuccessBeep,
  playActionRequiredBeep
} from '../../sounds';
import { useDistributionCenter } from '../../app/access-control/distribution-center-provider';
import { custodyExchange } from '../../api-rest';
import {
  CUSTODY_EXCHANGE_TYPE,
  ROUTES,
  DISTRIBUTE_TO,
  ACTIVITY,
  OPERATIONAL_PROCESS
} from '../../constants';
import { DistributeProcessContext } from './distribute-process-context';
import { ActivityTrackingContext } from '../../app/activity-tracking/activity-tracking-provider';

function BagPackageReader({
  goBack,
  vehicleLicensePlate,
  contextLicensePlate
}) {
  const { setDistributeType, setTotalCargosBeeped } = useContext(
    DistributeProcessContext
  );
  const { trackStart, trackEnd } = useContext(ActivityTrackingContext);
  const history = useHistory();
  const [barcodes, setBarcodes] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const {
    state: { selectedDistributionCenter }
  } = useDistributionCenter();

  const showSnackbarAlert = (message, variant = 'success') => {
    showSnackbar({
      variant,
      message,
      showCloseButton: true,
      enqueueSnackbar
    });
  };

  const handleBarcodeRead = barcode => {
    trackStart(
      OPERATIONAL_PROCESS.BEEP_LATENCY,
      ACTIVITY.DISTRIBUTE_PARTNER_BAG_PACKAGE_BEEP
    );
    setBarcodes(prevBarcodes => {
      if (!prevBarcodes.includes(barcode)) {
        playSuccessBeep();
        return [...prevBarcodes, barcode];
      }
      playActionRequiredBeep();
      showSnackbarAlert('Código de barras já lido!', 'warning');
      return prevBarcodes;
    });
    trackEnd(
      OPERATIONAL_PROCESS.BEEP_LATENCY,
      ACTIVITY.DISTRIBUTE_PARTNER_BAG_PACKAGE_BEEP
    );
  };

  let custodyExchangeType = CUSTODY_EXCHANGE_TYPE.REVERSE;

  if (contextLicensePlate?.includes('REV')) {
    custodyExchangeType = CUSTODY_EXCHANGE_TYPE.REVERSE;
  }

  const custodyExchangeCall = async () => {
    try {
      const response = await custodyExchange(
        vehicleLicensePlate,
        barcodes,
        custodyExchangeType,
        selectedDistributionCenter?.distributionCenterId
      );
      return response;
    } catch (err) {
      playErrorBeep();
      showSnackbarAlert(
        `ERRO: ${err.response?.data?.message || err.message}`,
        'error'
      );
      return null;
    }
  };

  const handleSubmit = async event => {
    event.preventDefault();
    trackStart(
      OPERATIONAL_PROCESS.DISTRIBUTE,
      ACTIVITY.DISTRIBUTE_PARTNER_CUSTODY_EXCHANGE
    );

    const response = await custodyExchangeCall();

    trackEnd(
      OPERATIONAL_PROCESS.DISTRIBUTE,
      ACTIVITY.DISTRIBUTE_PARTNER_CUSTODY_EXCHANGE
    );
    if (response != null && response.status === 200) {
      setTotalCargosBeeped(barcodes.length);
      setDistributeType(DISTRIBUTE_TO.PARTNER);
      history.push({
        pathname: ROUTES.DISTRIBUTE.NEW_DISTRIBUTION
      });
      return;
    }
    history.push({
      pathname: ROUTES.HOME
    });
  };

  return (
    <Box
      bgcolor={colors.root[0]}
      height="100vh"
      display="flex"
      flexDirection="column"
      data-testid="distribute-bag-package-reader"
    >
      <Container
        maxWidth="xs"
        style={{ flex: 1, display: 'flex', flexDirection: 'column' }}
      >
        <Box pt={3}>
          <HeaderWithCancel
            testId="cancel-button"
            handleClose={goBack}
            isDisabled={false}
          />
          <Box paddingTop={1.5}>
            <Typography component="div" variant="body1" gutterBottom>
              <Box fontWeight="fontWeightBold">Leitura das Sacas</Box>
            </Typography>
          </Box>
          <Chip
            style={{
              backgroundColor: colors.smoke[100],
              color: colors.root[900]
            }}
            icon={<LocalShippingIcon />}
            size="medium"
            variant="outlined"
            label={vehicleLicensePlate}
          />
        </Box>
        <Box flex={1} overflow="auto" style={{ marginBottom: '80px' }}>
          <Box my={2.5} className="centered">
            <BarcodeReader
              dataTestId="barcode-input"
              onRead={handleBarcodeRead}
              placeholder="Leia o código de barras"
              notes={`Placa do veículo: ${vehicleLicensePlate}`}
            />
          </Box>

          <Box mt={2}>
            {barcodes.map((code, index) => (
              <Box
                key={code}
                mb={index === barcodes.length - 1 ? 0 : 1}
                data-testid={`barcode-item-${code}`}
              >
                <Divider />
                <Box
                  mt={1}
                  mb={index === barcodes.length - 1 ? 1 : 0}
                  maxWidth="300px"
                >
                  <ListItem style={{ paddingLeft: '0' }}>
                    <ListItemAvatar>
                      <Avatar
                        style={{
                          width: '40px',
                          height: '30px',
                          borderStyle: 'solid',
                          borderWidth: 'thin',
                          backgroundColor: 'transparent',
                          borderColor: colors.blue[500],
                          borderRadius: 10
                        }}
                      >
                        <Typography
                          display="block"
                          style={{ fontWeight: 600, color: colors.root[900] }}
                        >
                          {index + 1}
                        </Typography>
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <Typography
                          display="block"
                          style={{ fontWeight: 'bold' }}
                        >
                          {code}
                        </Typography>
                      }
                    />
                  </ListItem>
                </Box>
                {index === barcodes.length - 1 && <Divider />}
              </Box>
            ))}
          </Box>
        </Box>
      </Container>
      <Container
        maxWidth="xs"
        style={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}
      >
        <Box flex="none" mb={3}>
          <Button
            type="submit"
            fullWidth
            size="large"
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={barcodes.length === 0}
          >
            Enviar Lista
          </Button>
        </Box>
      </Container>
    </Box>
  );
}

BagPackageReader.propTypes = {
  vehicleLicensePlate: PropTypes.string,
  contextLicensePlate: PropTypes.string,
  goBack: PropTypes.func
};

BagPackageReader.defaultProps = {
  vehicleLicensePlate: '',
  contextLicensePlate: 'DOCA REV',
  goBack: () => {}
};

export default BagPackageReader;
