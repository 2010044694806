import React, { useContext, useEffect } from 'react';
import { colors } from '@loggi/mar';
import { Box, Button, Container, Divider, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import ActionContainerBottom from '../../app/components/actions-container-bottom';
import FloatingContainer from '../../app/components/floating-container';
import HeaderWithReturn from '../../app/components/header-with-return';
import AvatarChip from '../../app/components/avatar-chip';
import SortingRecommendation from './sorting-recommendation';
import ReceiveCheck from './receive-check';
import { ReceivingProcessContext } from './receiving-process-context';
import IncompleteReceiveScreenBody from './incomplete-receive';
import HeaderWithClose from '../../app/components/header-with-close';

export default function NewReceivePackage({
  goBack,
  totalPackages,
  receivedPackages,
  sortingContext,
  sender,
  children,
  completeReceive,
  receiveSuccess
}) {
  const {
    incompleteReceive,
    receiveSortingContext,
    setIncompleteReceive
  } = useContext(ReceivingProcessContext);

  const siblingSorterLicensePlate =
    receiveSortingContext &&
    'siblingsSorterLicensePlate' in receiveSortingContext
      ? receiveSortingContext?.siblingsSorterLicensePlate[0]
      : null;

  const lastPackageReaded = receivedPackages[0] || {};
  const [showList, setShowList] = React.useState(false);

  const toggleList = () => {
    setShowList(!showList);
  };

  useEffect(() => {
    if (showList && incompleteReceive) {
      setShowList(!showList);
    }
  }, [incompleteReceive, showList]);

  return (
    <>
      {showList && (
        <ReceiveCheck
          packages={receivedPackages}
          finishReceivement={() => completeReceive()}
          goBack={toggleList}
        />
      )}
      {!showList && incompleteReceive && (
        <Box height="100vh" clone>
          <Container maxWidth="xs">
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              height="100%"
              overflow="hidden"
            >
              <Box data-testid="incomplete-receive-header-component">
                <HeaderWithClose
                  testId="receive-cancel-button"
                  handleClose={async () => {
                    setIncompleteReceive(false);
                  }}
                />
              </Box>

              <IncompleteReceiveScreenBody
                receiveSuccess={() => receiveSuccess()}
              />
            </Box>
          </Container>
        </Box>
      )}
      {!showList && !incompleteReceive && (
        <Box
          data-testid="new-receive-package"
          height="100vh"
          overflow="hidden"
          bgcolor={colors.root[0]}
        >
          <Container maxWidth="xs">
            <Box pt={1.5}>
              <HeaderWithReturn
                title="Receber"
                onReturn={goBack}
                fixed={false}
              />
            </Box>
            {children}
            {Object.keys(sender).length !== 0 && (
              <Box mt={2.25}>
                <AvatarChip
                  photo={sender.photoUrl}
                  name={sender.name}
                  hasCheckIcon={false}
                />
                <Typography component={Box} variant="body1">
                  <Box mt={1} fontWeight="fontWeightRegular" fontSize="small">
                    veio deixar {totalPackages} un.
                  </Box>
                </Typography>
              </Box>
            )}

            <Box mt={8.5} display="flex" alignItems="flex-end">
              <Typography variant="body1">
                <Box fontWeight="fontWeightRegular" fontSize="5.375rem">
                  {receivedPackages.length}
                </Box>
              </Typography>
              <Typography variant="body1">
                <Box mb={3} fontWeight="fontWeightRegular">
                  &nbsp;un.
                </Box>
              </Typography>
            </Box>
          </Container>
          <Box maxWidth="444px" mx="auto" height="100%" px={{ sm: '1.25rem' }}>
            <FloatingContainer>
              <>
                <Box mb={3.75}>
                  {lastPackageReaded.package && (
                    <SortingRecommendation
                      isNewReceive
                      barcode={lastPackageReaded.package}
                      sortingContext={sortingContext}
                      siblingSorterLicensePlate={siblingSorterLicensePlate}
                    />
                  )}
                </Box>
                <Divider />
                <Box mt={3.75} mb={3.75} display="flex" flexDirection="column">
                  <Typography variant="body2" gutterBottom>
                    <Box fontWeight="600" fontSize="1.125rem">
                      {lastPackageReaded.package}
                    </Box>
                  </Typography>
                  {lastPackageReaded.company && (
                    <Box
                      color={colors.smoke[700]}
                      mb={0.5}
                      fontWeight="400"
                      fontSize="1rem"
                      textOverflow="ellipsis"
                      whiteSpace="nowrap"
                      overflow="hidden"
                    >
                      Pacote de {lastPackageReaded.company}
                    </Box>
                  )}
                </Box>
              </>
            </FloatingContainer>
          </Box>
          <Container maxWidth="xs">
            <ActionContainerBottom>
              <Container maxWidth="xs">
                <Box pb={3.125}>
                  <Button
                    fullWidth
                    color="primary"
                    size="large"
                    variant="contained"
                    onClick={toggleList}
                  >
                    Conferir Lista
                  </Button>
                </Box>
              </Container>
            </ActionContainerBottom>
          </Container>
        </Box>
      )}
    </>
  );
}

NewReceivePackage.defaultProps = {
  receiveSuccess: () => {}
};

NewReceivePackage.propTypes = {
  goBack: PropTypes.func.isRequired,
  totalPackages: PropTypes.number.isRequired,
  receivedPackages: PropTypes.arrayOf(
    PropTypes.shape({
      package: PropTypes.string
    })
  ).isRequired,
  sortingContext: PropTypes.string.isRequired,
  sender: PropTypes.shape({
    photoUrl: PropTypes.string,
    name: PropTypes.string
  }).isRequired,
  children: PropTypes.element.isRequired,
  completeReceive: PropTypes.func.isRequired,
  receiveSuccess: PropTypes.func
};
