import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Typography, Container } from '@material-ui/core';
import { colors } from '@loggi/mar';
import { useSnackbar } from 'notistack';
import { playErrorBeep, playSuccessBeep } from '../../sounds';
import SortingContextReader from '../../app/components/sorting-context-reader';
import showSnackbar from '../../app/components/snackbar/snackbar-container';
import { ROUTES, ACTIVITY, OPERATIONAL_PROCESS } from '../../constants';
import HeaderWithCancel from '../../app/components/header-with-cancel';
import { ActivityTrackingContext } from '../../app/activity-tracking/activity-tracking-provider';

export default function PartnerContextReader() {
  const history = useHistory();
  const { trackStart } = useContext(ActivityTrackingContext);
  const goBack = () => {
    history.push({
      pathname: ROUTES.DISTRIBUTE.IDENTIFICATION
    });
  };
  const { enqueueSnackbar } = useSnackbar();
  const showSnackbarAlert = (message, variant = 'success') => {
    showSnackbar({
      variant,
      message,
      showCloseButton: true,
      enqueueSnackbar
    });
  };

  const handleRestContextRead = async sorting => {
    if (sorting.licensePlate.includes('REV')) {
      playSuccessBeep();
      showSnackbarAlert(`${sorting.licensePlate} selecionado!`, 'success');
      trackStart(
        OPERATIONAL_PROCESS.DISTRIBUTE,
        ACTIVITY.DISTRIBUTE_PARTNER_CONTEXT_READER
      );
      history.push({
        pathname: ROUTES.DISTRIBUTE_PARTNER,
        state: {
          sortingContextLicensePlate: sorting.licensePlate
        }
      });
    } else {
      playErrorBeep();
      showSnackbarAlert('Operação não permitida!', 'error');
    }
  };

  return (
    <Box
      bgcolor={colors.root[0]}
      height="100vh"
      display="flex"
      flexDirection="column"
      data-testid="distribute-partner-context-reader"
    >
      <Container
        maxWidth="xs"
        style={{ flex: 1, display: 'flex', flexDirection: 'column' }}
      >
        <Box pt={3}>
          <HeaderWithCancel
            testId="cancel-button"
            handleClose={goBack}
            isDisabled={false}
          />
          <Box paddingTop={1.5}>
            <Typography component="div" variant="body1" gutterBottom>
              <Box fontWeight="fontWeightBold">Distribuir</Box>
            </Typography>
          </Box>
          <Box mt={1.5}>
            <Typography variant="h5">
              Para começar, bipe o código da operação
            </Typography>
          </Box>
        </Box>
        <SortingContextReader
          onRead={handleRestContextRead}
          placeholder="Leia o código da operação"
          disableFetchDecisions
          aggregated={false}
        />
      </Container>
    </Box>
  );
}
