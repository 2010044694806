/* eslint-disable react/forbid-prop-types */
/* eslint-disable consistent-return */
/* eslint-disable react/destructuring-assignment */

import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/pt-br';
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  GridList,
  Typography,
  ListItem,
  List
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import { colors } from '@loggi/mar';
import { pxToRem } from '@loggi/mar/src/utils';
import { playErrorBeep, playSuccessBeep } from '../../sounds';
import {
  BarcodeReader,
  PackageReader
} from '../../app/components/barcode-readers';
import DecorativeHeader from '../../app/components/decorative-header';
import HeaderWithButton from '../../app/components/header-with-button';
import IncompleteReceiveScreenBody from './incomplete-receive';
import showSnackbar from '../../app/components/snackbar/snackbar-container';
import { identifyPackagesToReceive, movePackage } from '../../api-rest';
import pluralize from '../../app/utils/pluralize';
import {
  ACTIVITY,
  COGNITO_DISTRIBUTION_CENTER,
  HISTORY_ACTIONS,
  OPERATIONAL_PROCESS,
  RESPONSE_STATUS,
  ROUTES,
  SWITCHES,
  UNIFIED_RECEIVE_UNIT_LOAD_SUFFIX
} from '../../constants';
import handleRestAPIError from '../../app/utils/rest-api-request';
import { PACKAGE_ORIGIN } from '../../app/enums';
import { useDistributionCenter } from '../../app/access-control/distribution-center-provider';
import LogoCorreios from '../../assets/images/correios-logo.svg';
import { ReceivingProcessContext } from './receiving-process-context';
import { ActivityTrackingContext } from '../../app/activity-tracking/activity-tracking-provider';
import SortingRecommendation from './sorting-recommendation';
import { useFeature } from '../../app/hooks/use-feature';
import getUserType from '../../app/access-control/access-control-service';
import NewReceivePackage from './new-receive';
import HeaderWithClose from '../../app/components/header-with-close';

moment.updateLocale('pt-BR');

const useStyles = makeStyles(theme => ({
  large: {
    width: pxToRem(100),
    height: pxToRem(100)
  },
  smallAvatar: {
    width: pxToRem(70),
    height: pxToRem(70)
  },
  largeButton: {
    width: '100%',
    paddingBottom: pxToRem(15)
  },
  grid: {
    paddingTop: pxToRem(20)
  },
  flexBox: {
    display: 'flex',
    flexFlow: 'column',
    height: '100%'
  },
  flexBody: {
    paddingTop: pxToRem(20),
    flex: '1 1 auto'
  },
  flexBottom: {
    flex: '0 1 auto',
    paddingBottom: theme.spacing(3)
  },
  flexName: {
    display: 'flex',
    justifyContent: 'center',
    flexFlow: 'column'
  },
  flexAvatar: {
    display: 'flex',
    cursor: 'pointer',
    justifyContent: 'center',
    alignItems: 'self-end',
    flexFlow: 'column'
  }
}));

export const TASK_ACK_STATUS_CHOICES = {
  IMPAIRED_DELIVERY: 'Entrega prejudicada'
};

export function isOriginPickup(origin) {
  return origin === PACKAGE_ORIGIN.PACKAGE_ORIGIN_PICKUP;
}

export const resolvePackageStatusMessage = pack => {
  return pack?.statusDisplay && pack?.deliveryAttempts
    ? `${pack?.statusDisplay} - tentativa ${pack?.deliveryAttempts}`
    : '';
};

const header = (classes, sender, finish) => {
  return (
    <Box className={classes.grid}>
      {sender ? (
        <DecorativeHeader />
      ) : (
        <HeaderWithButton
          testId="receive-cancel-button"
          handleButtonClick={() => finish()}
          buttonLabel="Cancelar"
        />
      )}
    </Box>
  );
};

const resolveHeader = (
  incompleteReceive,
  shouldShowSender,
  shouldMove,
  setIncompleteReceive,
  classes,
  sender,
  finish
) => {
  if (incompleteReceive)
    return (
      <Box>
        <HeaderWithClose
          testId="receive-cancel-button"
          handleClose={async () => {
            setIncompleteReceive(false);
          }}
        />
      </Box>
    );

  return (
    <Box>
      {header(classes, sender, finish)}
      <Box paddingTop={1.5}>
        <Typography component="div" variant="body1" gutterBottom>
          {shouldShowSender && (
            <Box fontWeight="fontWeightBold">Recebendo de</Box>
          )}
          {!shouldShowSender && shouldMove && (
            <Box fontWeight="fontWeightBold">Armazenar</Box>
          )}
          {!shouldShowSender && !shouldMove && (
            <Box fontWeight="fontWeightBold">Receber</Box>
          )}
        </Typography>
      </Box>
    </Box>
  );
};

const resolveBody = ({
  sender,
  shouldShowSender,
  incompleteReceive,
  receiveSuccess,
  classes,
  packagesHaveItinerary,
  receivedSenderPackages,
  senderPackages,
  showSender,
  setPackageBarcode,
  shouldReceivePackage,
  loading,
  errorMessage,
  shouldFinish,
  completeReceive
}) => {
  if (sender && shouldShowSender)
    return (
      <Box className={classes.flexBox}>
        <SenderDetailsScreen />
      </Box>
    );

  if (incompleteReceive)
    return (
      <Box className={classes.flexBox}>
        <IncompleteReceiveScreenBody receiveSuccess={receiveSuccess} />
      </Box>
    );

  return (
    <Box className={classes.flexBox}>
      <BeepPackageScreenComponent
        classes={classes}
        sender={sender}
        packagesHaveItinerary={packagesHaveItinerary}
        receivedSenderPackages={receivedSenderPackages}
        senderPackages={senderPackages}
        showSender={showSender}
        setPackageBarcode={setPackageBarcode}
        shouldReceivePackage={shouldReceivePackage}
        loading={loading}
        errorMessage={errorMessage}
        shouldFinish={shouldFinish}
        completeReceive={completeReceive}
      />
    </Box>
  );
};

resolveBody.propTypes = {
  sender: PropTypes.any.isRequired,
  shouldShowSender: PropTypes.any.isRequired,
  incompleteReceive: PropTypes.any.isRequired,
  receiveSuccess: PropTypes.any.isRequired,
  classes: PropTypes.any.isRequired,
  packagesHaveItinerary: PropTypes.any.isRequired,
  receivedSenderPackages: PropTypes.any.isRequired,
  senderPackages: PropTypes.any.isRequired,
  showSender: PropTypes.any.isRequired,
  setPackageBarcode: PropTypes.any.isRequired,
  shouldReceivePackage: PropTypes.any.isRequired,
  loading: PropTypes.any.isRequired,
  errorMessage: PropTypes.any.isRequired,
  shouldFinish: PropTypes.any.isRequired,
  completeReceive: PropTypes.any.isRequired
};

const BeepPackageScreenComponent = ({
  classes,
  sender,
  packagesHaveItinerary,
  receivedSenderPackages,
  senderPackages,
  showSender,
  setPackageBarcode,
  shouldReceivePackage,
  loading,
  errorMessage,
  shouldFinish,
  completeReceive
}) => {
  return (
    <Box className={classes.flexBox}>
      {sender ? (
        <Box>
          {packagesHaveItinerary && (
            <Box>
              <Typography
                variant="h3"
                style={{ color: colors.blue[500] }}
                display="inline"
              >
                {receivedSenderPackages}{' '}
              </Typography>
              <Typography variant="h3" display="inline">
                de {senderPackages.length}
              </Typography>
              <Typography variant="body1">
                {`${pluralize({
                  singular: 'pacote',
                  count: senderPackages.length
                })}`}
              </Typography>
            </Box>
          )}
          <SenderCard sender={sender} showSender={showSender} />
        </Box>
      ) : (
        <Box>
          <Typography variant="h5">
            Bipe o lacre ou etiqueta e comece a receber
          </Typography>
        </Box>
      )}
      <ReturnPackageReader
        changeCallback={async () => {
          setPackageBarcode('');
        }}
        confirmationCallback={async responsePackage => {
          shouldReceivePackage(responsePackage);
        }}
        senderPackages={senderPackages}
        packagesHaveItinerary={packagesHaveItinerary}
      />
      {loading && (
        <Box ml={1}>
          <CircularProgress />
        </Box>
      )}
      {errorMessage && (
        <Box>
          <Alert severity="error">Erro ao receber: {errorMessage}</Alert>
        </Box>
      )}
      {shouldFinish && (
        <>
          <Box flex={1} overflow="auto" mb={2}>
            <ReceivedPackagesList />
          </Box>
          <Box pb={3}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              className={classes.largeButton}
              onClick={completeReceive}
            >
              Pronto, recebidos
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
};

BeepPackageScreenComponent.propTypes = {
  classes: PropTypes.any.isRequired,
  sender: PropTypes.any.isRequired,
  packagesHaveItinerary: PropTypes.any.isRequired,
  receivedSenderPackages: PropTypes.any.isRequired,
  senderPackages: PropTypes.any.isRequired,
  showSender: PropTypes.any.isRequired,
  setPackageBarcode: PropTypes.any.isRequired,
  shouldReceivePackage: PropTypes.any.isRequired,
  loading: PropTypes.any.isRequired,
  errorMessage: PropTypes.any.isRequired,
  shouldFinish: PropTypes.any.isRequired,
  completeReceive: PropTypes.any.isRequired
};

const isDuplicatedPackageBeep = (barcode, returnList) =>
  returnList.filter(item => item.package === barcode)[0];

const isToReceiveContextReader = (history, sortingContext) => {
  if (history.action !== HISTORY_ACTIONS.PUSH || !sortingContext) {
    history.replace('/receive/context-reader');
  }
};

export default function ReceivePackage() {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const {
    state: { selectedDistributionCenter }
  } = useDistributionCenter();
  const {
    sortingContext,
    setSortingContext,
    setDestination,
    errorMessage,
    setErrorMessage,
    packageBarcode,
    setPackageBarcode,
    loading,
    setLoading,
    setPackToReceive,
    packToReceive,
    receivedSenderPackages,
    setReceivedSenderPackages,
    returnList,
    setReturnList,
    sender,
    setSender,
    senderPackages,
    setSenderPackages,
    shouldFinish,
    setShouldFinish,
    shouldMove,
    setShouldMove,
    shouldShowSender,
    setShouldShowSender,
    incompleteReceive,
    setIncompleteReceive,
    packagesHaveItinerary,
    packagesOrigin
  } = useContext(ReceivingProcessContext);
  const { trackEnd } = useContext(ActivityTrackingContext);
  const disablePickupBulkOnReceive = useFeature(
    SWITCHES.disablePickupBulkOnReceive
  );
  const enableNewReceiveFlow = useFeature(SWITCHES.enableNewReceiveFlow);

  const defaultReceivingUnitLoad = `${
    selectedDistributionCenter.routingCode
  } ${UNIFIED_RECEIVE_UNIT_LOAD_SUFFIX}`;

  useEffect(() => {
    // prevent user for opening this page when editing the URL
    isToReceiveContextReader(history, sortingContext);
  }, [history, sortingContext]);

  const clearData = () => {
    setDestination(null);
    setPackageBarcode('');
    setPackToReceive(null);
    setReturnList([]);
    setReceivedSenderPackages(0);
    setSender(null);
    setSenderPackages([]);
    setShouldFinish(false);
    setShouldMove(false);
    setShouldShowSender(false);
    setIncompleteReceive(false);
    setSortingContext(null);
  };

  const finish = (pathname = '/') => {
    history.push({ pathname });
  };

  const toReceiveConfirmation = () => {
    history.replace({
      pathname: ROUTES.RECEIVE.CONFIRMATION,
      state: {
        totalPackagesReceived: returnList.length,
        senderName: sender?.name || '',
        receivedIn: moment(new Date()).format('DD MMM[,] HH:mm')
      }
    });
  };

  const receiveSuccess = () => {
    playSuccessBeep();
    trackEnd(OPERATIONAL_PROCESS.RECEIVE, ACTIVITY.BEEP_PACKAGE);
    trackEnd(OPERATIONAL_PROCESS.RECEIVE, ACTIVITY.COMMON_RECEIVE);
    trackEnd(OPERATIONAL_PROCESS.RECEIVE, ACTIVITY.FULL_PROCESS);
    trackEnd(OPERATIONAL_PROCESS.BEEP_LATENCY, ACTIVITY.FULL_PROCESS);

    const isUserLeve =
      getUserType(selectedDistributionCenter) ===
      COGNITO_DISTRIBUTION_CENTER.LEVE_USER;

    if (enableNewReceiveFlow) {
      toReceiveConfirmation();
      return;
    }

    if (
      !disablePickupBulkOnReceive &&
      !isUserLeve &&
      packagesOrigin === PACKAGE_ORIGIN.PACKAGE_ORIGIN_PICKUP
    ) {
      finish(ROUTES.RECEIVE.RECEIVED_PACKAGES);
      return;
    }

    showSnackbar({
      variant: 'success',
      message: 'Pronto, tudo certo.\nPacotes recebidos.',
      showCloseButton: true,
      enqueueSnackbar
    });

    clearData();
    finish();
  };

  const warningAlert = message => {
    showSnackbar({
      variant: 'warning',
      message,
      showCloseButton: true,
      enqueueSnackbar
    });
    playErrorBeep();
  };

  const isReceiveIncomplete = () =>
    packagesHaveItinerary && receivedSenderPackages < senderPackages.length;

  const completeReceive = () => {
    if (isReceiveIncomplete()) {
      setIncompleteReceive(true);
      return;
    }

    receiveSuccess();
  };

  const showSender = async () => {
    setShouldShowSender(true);
  };

  const cancel = () => {
    setShouldMove(false);
    setPackageBarcode('');
  };

  const isFromSameOrigin = barcode => {
    return senderPackages.filter(pack => pack.barcode === barcode).length > 0;
  };

  const addReturn = ({
    packageLP,
    deliveryStatus,
    unitLoadLP,
    receivedUnitLoadId,
    company
  }) => {
    const isSameOrigin = isFromSameOrigin(packageLP);
    if (isSameOrigin) setReceivedSenderPackages(receivedSenderPackages + 1);

    setReturnList([
      {
        package: packageLP,
        deliveryStatus,
        unitLoad: unitLoadLP,
        isSameOrigin,
        unitLoadId: receivedUnitLoadId,
        company
      },
      ...returnList
    ]);
  };

  const baseErrorHandler = message => {
    setErrorMessage(message);
    playErrorBeep();
    setLoading(false);
  };

  const movePackageForDefaultUL = barcode => {
    return movePackage(
      defaultReceivingUnitLoad,
      barcode,
      defaultReceivingUnitLoad,
      sortingContext,
      {
        distributionCenterId: selectedDistributionCenter?.distributionCenterId
      }
    );
  };

  const sortReceivedPackage = async (barcode, packageId, company) => {
    if (loading) return;

    setLoading(true);
    setErrorMessage('');

    try {
      await movePackageForDefaultUL(barcode);
      const packageStatusMessage = resolvePackageStatusMessage(packToReceive);
      addReturn({
        packageLP: barcode,
        deliveryStatus: packageStatusMessage,
        unitLoadLP: defaultReceivingUnitLoad,
        receivedUnitLoadId: packageId,
        company
      });
      setLoading(false);
      setShouldFinish(true);
      playSuccessBeep();
    } catch (err) {
      handleRestAPIError(err, baseErrorHandler);
    }
  };

  const shouldReceivePackage = async responsePackage => {
    setPackToReceive(responsePackage);
    setPackageBarcode(responsePackage.barcode);

    if (isDuplicatedPackageBeep(responsePackage.barcode, returnList)) {
      warningAlert('Opa, pacote já bipado!');
      cancel();
      return;
    }

    await sortReceivedPackage(
      responsePackage.barcode,
      responsePackage.packageId,
      responsePackage.company
    );
    trackEnd(OPERATIONAL_PROCESS.BEEP_LATENCY, ACTIVITY.RECEIVE_PACKAGE_BEEP);
  };

  /**
   * For the first package only
   * we execute this effect
   */
  useEffect(() => {
    (async function processPackage() {
      if (packageBarcode)
        await sortReceivedPackage(
          packageBarcode,
          packToReceive?.packageId,
          packToReceive?.company
        );
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return enableNewReceiveFlow ? (
    <Box>
      {sortingContext && (
        <NewReceivePackage
          goBack={() => finish()}
          totalPackages={senderPackages.length}
          receivedPackages={returnList}
          sender={sender}
          sortingContext={sortingContext}
          completeReceive={completeReceive}
          receiveSuccess={receiveSuccess}
        >
          <ReturnPackageReader
            changeCallback={async () => {
              setPackageBarcode('');
            }}
            confirmationCallback={async responsePackage => {
              shouldReceivePackage(responsePackage);
            }}
            senderPackages={senderPackages}
            packagesHaveItinerary={packagesHaveItinerary}
            placeholder="Bipe o código da unidade"
          />
        </NewReceivePackage>
      )}
    </Box>
  ) : (
    <Box>
      {sortingContext && (
        <Box height="100vh" clone>
          <Container maxWidth="xs">
            <Box className={classes.flexBox} overflow="hidden">
              <Box>
                {resolveHeader(
                  incompleteReceive,
                  shouldShowSender,
                  shouldMove,
                  setIncompleteReceive,
                  classes,
                  sender,
                  finish
                )}
              </Box>
              <Box overflow="hidden" flex={1}>
                {resolveBody({
                  sender,
                  shouldShowSender,
                  incompleteReceive,
                  receiveSuccess,
                  classes,
                  packagesHaveItinerary,
                  receivedSenderPackages,
                  senderPackages,
                  showSender,
                  setPackageBarcode,
                  shouldReceivePackage,
                  loading,
                  errorMessage,
                  shouldFinish,
                  completeReceive
                })}
              </Box>
            </Box>
          </Container>
        </Box>
      )}
    </Box>
  );
}

const ReceivedPackageItem = ({ receivedPackage, bottomDivider }) => {
  const { sortingContext } = useContext(ReceivingProcessContext);

  return (
    <Box
      mb={bottomDivider ? 0 : 1}
      data-testid={`package-item-${receivedPackage.package}`}
    >
      <Divider />
      <Box mt={1} mb={bottomDivider ? 1 : 0} paddingLeft={0} clone>
        <ListItem>
          <Box>
            {receivedPackage.deliveryStatus && (
              <Typography variant="body2">
                {receivedPackage.deliveryStatus}
              </Typography>
            )}
            <Typography display="block" variant="body2" gutterBottom>
              #{receivedPackage.package}
            </Typography>
            {!receivedPackage.isSameOrigin && (
              <Typography variant="body2">
                pacote de outro entregador
              </Typography>
            )}
            <SortingRecommendation
              sortingContext={sortingContext}
              barcode={receivedPackage.package}
            />
          </Box>
        </ListItem>
      </Box>
      {bottomDivider && <Divider />}
    </Box>
  );
};

ReceivedPackageItem.defaultProps = {
  bottomDivider: false
};

ReceivedPackageItem.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  receivedPackage: PropTypes.object.isRequired,
  bottomDivider: PropTypes.bool
};

export function ReceivedPackagesList() {
  const { returnList } = useContext(ReceivingProcessContext);

  return (
    <List data-testid="package-list">
      {returnList.map((item, i) => (
        <ReceivedPackageItem
          key={item.package}
          receivedPackage={item}
          bottomDivider={returnList.length - 1 === i}
        />
      ))}
    </List>
  );
}

export function SenderCard({ sender, showSender, logoCorreiosVisible }) {
  const classes = useStyles();
  return (
    <GridList cellHeight="auto" cols={5} spacing={1}>
      <Box cols={3.5} className={classes.flexName}>
        <Typography variant="body1">{sender.name}</Typography>
        {sender.cpf && (
          <Typography variant="caption">CPF: {sender.cpf}</Typography>
        )}
      </Box>
      <Box
        cols={1.5}
        className={classes.flexAvatar}
        onClick={showSender}
        data-testid="sender-avatar"
      >
        {(sender.photoUrl || logoCorreiosVisible) && (
          <Avatar
            src={logoCorreiosVisible ? LogoCorreios : sender.photoUrl}
            className={classes.smallAvatar}
          />
        )}
      </Box>
    </GridList>
  );
}

SenderCard.defaultProps = {
  logoCorreiosVisible: false
};

SenderCard.propTypes = {
  sender: PropTypes.shape({
    cpf: PropTypes.string,
    name: PropTypes.string,
    photoUrl: PropTypes.string
  }).isRequired,
  logoCorreiosVisible: PropTypes.bool,
  showSender: PropTypes.func.isRequired
};

export function SelectDestinationScreen({ onMoveCompleted }) {
  const classes = useStyles();
  const {
    packageBarcode,
    packToReceive,
    destination,
    returnCause,
    sortingContext
  } = useContext(ReceivingProcessContext);
  const { trackStart, trackEnd } = useContext(ActivityTrackingContext);

  const packageStatusMessage =
    returnCause?.notes || resolvePackageStatusMessage(packToReceive);
  const sideEffectParams = { ...returnCause };

  useEffect(() => {
    trackStart(OPERATIONAL_PROCESS.RECEIVE, ACTIVITY.BEEP_DESTINATION);

    return () => {
      trackEnd(OPERATIONAL_PROCESS.RECEIVE, ACTIVITY.BEEP_DESTINATION);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box className={classes.flexBox}>
      <Typography variant="h5">em {destination.name}</Typography>
      <Box className={classes.grid}>
        {packToReceive && (
          <Box>
            {packageStatusMessage && (
              <Typography variant="body1">{packageStatusMessage}</Typography>
            )}
          </Box>
        )}
        <Box className={classes.grid}>
          <Divider />
          <Box className={classes.grid}>
            <Typography variant="body1">#{packageBarcode}</Typography>
          </Box>
        </Box>
      </Box>
      <ReturnUnitLoadReader
        packageBarcode={packageBarcode}
        sortingDecisionLpn={destination.licensePlate}
        changeCallback={async () => {
          // Intentionally left empty
        }}
        sideEffectParams={sideEffectParams}
        confirmationCallback={licensePlate =>
          onMoveCompleted(licensePlate, packageStatusMessage)
        }
        sortingContextLpn={sortingContext}
      />
    </Box>
  );
}

SelectDestinationScreen.propTypes = {
  onMoveCompleted: PropTypes.func.isRequired
};

export function SenderDetailsScreen() {
  const classes = useStyles();
  const { sender, setShouldShowSender } = useContext(ReceivingProcessContext);
  return (
    <Box className={classes.flexBox}>
      <Box className={classes.flexBody}>
        <Box align="center">
          <Avatar src={sender.photoUrl} className={classes.large} />
        </Box>
        <Box className={classes.grid} align="center">
          <Typography variant="h6">{sender.name}</Typography>
          {sender.cpf && (
            <Typography variant="caption">CPF: {sender.cpf}</Typography>
          )}
        </Box>
        {sender.onboardDate && (
          <Typography variant="body1" align="center" className={classes.grid}>
            entregando desde {moment(sender.onboardDate).format('DD MMM YYYY')}
          </Typography>
        )}
      </Box>
      <Box className={classes.flexBottom}>
        <Button
          variant="contained"
          color="primary"
          size="large"
          className={classes.largeButton}
          onClick={() => setShouldShowSender(false)}
        >
          Voltar
        </Button>
      </Box>
    </Box>
  );
}

export function ReturnPackageReader({
  changeCallback,
  confirmationCallback,
  senderPackages,
  packagesHaveItinerary,
  placeholder
}) {
  const [errorMessage, setErrorMessage] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  const [currentBarcode, setCurrentBarcode] = React.useState('');
  const { trackStart, trackEnd } = useContext(ActivityTrackingContext);
  const { setLastPackageOrigin } = useContext(ReceivingProcessContext);
  /*
    After the first beep, the packages with itinerary will search on the
    senderPackages Array for the missing packages.
 */
  const findPackageWithItineraryAndOnSender = barcode =>
    packagesHaveItinerary &&
    senderPackages &&
    senderPackages.filter(pack => pack.barcode === barcode)[0];

  const handleRead = async barcode => {
    if (loading) {
      return;
    }

    setCurrentBarcode(barcode);
  };

  useEffect(() => {
    if (!currentBarcode) {
      return;
    }

    trackStart(OPERATIONAL_PROCESS.BEEP_LATENCY, ACTIVITY.RECEIVE_PACKAGE_BEEP);

    const packageWithItineraryAndOnSender = findPackageWithItineraryAndOnSender(
      currentBarcode
    );

    if (packageWithItineraryAndOnSender) {
      confirmationCallback(
        packageWithItineraryAndOnSender,
        null,
        senderPackages,
        packagesHaveItinerary
      );
      setCurrentBarcode('');
      return;
    }

    /*
      On the first beep, or if the packages doesn't have a itinerary, or if
      the barcode wasn't found, a request is made to receive info about the
      packages && sender.
    */
    setLoading(true);
    identifyPackagesToReceive(currentBarcode)
      .then(response => {
        setLoading(false);

        if (response.data && response.data.missingPackages) {
          const receivedPackage = response.data.missingPackages.filter(
            pack => pack.barcode === currentBarcode
          )[0];

          setLastPackageOrigin(response.data?.origin);
          confirmationCallback(
            receivedPackage,
            response.data.senderInfo || {},
            response.data.missingPackages,
            response.data.origin
          );
          setCurrentBarcode('');
        }
      })
      .catch(err => {
        handleRestAPIError(err, errorMsg => setErrorMessage(errorMsg));
        playErrorBeep();
        setLoading(false);
        setCurrentBarcode('');
        trackEnd(
          OPERATIONAL_PROCESS.BEEP_LATENCY,
          ACTIVITY.RECEIVE_PACKAGE_BEEP
        );
      });

    trackEnd(OPERATIONAL_PROCESS.RECEIVE, ACTIVITY.BEEP_PACKAGE);
    trackStart(OPERATIONAL_PROCESS.RECEIVE, ACTIVITY.BEEP_PACKAGE);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentBarcode]);

  useEffect(() => {
    trackStart(OPERATIONAL_PROCESS.RECEIVE, ACTIVITY.COMMON_RECEIVE);
    trackStart(OPERATIONAL_PROCESS.RECEIVE, ACTIVITY.BEEP_PACKAGE);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <Box my={2.5}>
        <PackageReader
          onRead={barcode => {
            setErrorMessage('');
            changeCallback();
            handleRead(barcode);
          }}
          onChange={async () => {
            setErrorMessage('');
            changeCallback();
          }}
          loading={loading}
          notes="Bipe na tela de recebimento"
          placeholder={placeholder}
        />
      </Box>
      {errorMessage && (
        <Box>
          <Alert severity="error">
            Erro ao identificar pacote: {errorMessage}
          </Alert>
        </Box>
      )}
    </Box>
  );
}

export function ReturnUnitLoadReader({
  packageBarcode,
  sortingDecisionLpn,
  sideEffectParams,
  changeCallback,
  confirmationCallback,
  sortingContextLpn
}) {
  const [errorMessage, setErrorMessage] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const { trackStart, trackEnd } = useContext(ActivityTrackingContext);
  const {
    state: { selectedDistributionCenter }
  } = useDistributionCenter();

  const handleChange = async () => {
    setErrorMessage('');
    changeCallback();
  };

  const sideEffectParamsWithDC = () => {
    return {
      ...sideEffectParams,
      distributionCenterId: selectedDistributionCenter?.distributionCenterId
    };
  };

  const handleRead = async licensePlate => {
    if (loading) {
      return;
    }
    trackStart(
      OPERATIONAL_PROCESS.BEEP_LATENCY,
      ACTIVITY.RECEIVE_DESTINATION_BEEP
    );

    setLoading(true);

    const response = await movePackage(
      licensePlate,
      packageBarcode,
      sortingDecisionLpn,
      sortingContextLpn,
      sideEffectParamsWithDC()
    ).catch(err => {
      handleRestAPIError(err, errorMsg => setErrorMessage(errorMsg));
      playErrorBeep();
      setLoading(false);
    });

    if (!response || !response.data) {
      trackEnd(
        OPERATIONAL_PROCESS.BEEP_LATENCY,
        ACTIVITY.RECEIVE_DESTINATION_BEEP
      );
      return;
    }

    if (response.data.status !== RESPONSE_STATUS.OK) {
      setErrorMessage(response.data.errorMsg);
      playErrorBeep();
      setLoading(false);
      trackEnd(
        OPERATIONAL_PROCESS.BEEP_LATENCY,
        ACTIVITY.RECEIVE_DESTINATION_BEEP
      );
      return;
    }
    setLoading(false);
    playSuccessBeep();
    confirmationCallback(licensePlate);
  };
  return (
    <Box>
      <Box my={2.5}>
        <BarcodeReader
          onRead={handleRead}
          onChange={handleChange}
          loading={loading}
          placeholder="Leia um local para armazenar"
        />
      </Box>
      {errorMessage && (
        <Box>
          <Alert severity="error">
            Erro ao selecionar destino: {errorMessage}
          </Alert>
        </Box>
      )}
    </Box>
  );
}

ReturnPackageReader.defaultProps = {
  placeholder: 'Leia a etiqueta do pacote'
};

ReturnPackageReader.propTypes = {
  changeCallback: PropTypes.func.isRequired,
  confirmationCallback: PropTypes.func.isRequired,
  senderPackages: PropTypes.arrayOf(
    PropTypes.shape({
      integrationInfo: PropTypes.object
    })
  ).isRequired,
  packagesHaveItinerary: PropTypes.bool.isRequired,
  placeholder: PropTypes.string
};

ReturnUnitLoadReader.propTypes = {
  packageBarcode: PropTypes.string.isRequired,
  sortingDecisionLpn: PropTypes.string.isRequired,
  sideEffectParams: PropTypes.shape({
    distributionCenterId: PropTypes.number,
    notes: PropTypes.string
  }),
  changeCallback: PropTypes.func.isRequired,
  confirmationCallback: PropTypes.func.isRequired,
  sortingContextLpn: PropTypes.string.isRequired
};

ReturnUnitLoadReader.defaultProps = {
  sideEffectParams: {
    distributionCenterId: 0,
    notes: ''
  }
};
