import React, { useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import VehicleLicensePlateInput from './trip/vehicle-license-plate-input';
import { DISTRIBUTE_TO, OPERATIONAL_PROCESS, ACTIVITY } from '../../constants';
import { ActivityTrackingContext } from '../../app/activity-tracking/activity-tracking-provider';

function LicensePlateInputPage() {
  const { trackEnd } = useContext(ActivityTrackingContext);
  const location = useLocation();
  const { sortingContextLicensePlate } = location.state || {};

  useEffect(() => {
    trackEnd(
      OPERATIONAL_PROCESS.DISTRIBUTE,
      ACTIVITY.DISTRIBUTE_PARTNER_CONTEXT_READER
    );
  }, [trackEnd]);

  return (
    <VehicleLicensePlateInput
      transferType={DISTRIBUTE_TO.PARTNER}
      sortingContextLicensePlate={sortingContextLicensePlate}
    />
  );
}

export default LicensePlateInputPage;
